<template>
  <div class="select_course">
    <div class="content">
      <h2>欢迎使用“选科测评”！</h2>
      <ul>
        <li>测评没有对与错之分，根据您的日常实际表现真实做答,不是您期望的理想表现! 有些题目可能您从未碰到过或者难以选择,不要过多思考,凭第一感觉做答。</li>
      </ul>
      <el-button type="primary" @click="beginTest">开始测评</el-button>
    </div>
  </div>
</template>

<script>
import {dealQsData} from '../../utils'
export default {
  data() {
    return {};
  },
  mounted(){
    localStorage.removeItem('QuestionsStore');
    localStorage.removeItem('answerList');
    localStorage.removeItem('currentQsIndex');
  },
  methods:{
      async beginTest(){
        await this.getQuestions();
        this.$router.push({name: "AssessmentSystemTest", params: {type: 'course'} });
      },
      async getQuestions() {
        const apiUrl = "/api/question/all/?type=新高考选科测评";
        try {
          const response = await this.$axios.get(apiUrl);
          if (response.data.errno === 0) {
            console.log('----',response.data);
            response.data.results.results = dealQsData(response.data.results.results)
            this.questions = response.data.results;
            localStorage.setItem('QuestionsStore', JSON.stringify(this.questions));
            localStorage.setItem('currentQsIndex', 1);
          }
        } catch (error) {
          console.log(`question error: ${error}`);
        }
      },
  }
};
</script>

<style lang="scss" scoped>
.select_course {
  // min-height: calc(100vh - 310px);
  min-height: 100vh;
  padding-top: 51px;
  .content {
    background: #fff;
    margin-bottom: 100px;
    padding: 30px;
    h2 {
      font-size: 20px;
      color: #333;
      font-weight: 600;
      margin-bottom: 20px;
    }
    ul {
      padding-left: 20px;
      height: 2.5rem;
      li {
        height: 25px;
        line-height: 25px;
        list-style: disc;
      }
    }
  }
}
</style>